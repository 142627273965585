import React from "react";
import { i18n } from "../../../../translate/i18n";

import * as S from "./IndexesSection.style";

import StockTag from "../StockTag/StockTag";

export default function IndexesSection({ otbData, futureMonths, actions }) {
  const { t } = i18n;
  const language = localStorage.getItem("i18nextLng") || "pt";

  const allCategoriesObjs = otbData.flat();

  const futureMonthsObjs = allCategoriesObjs.filter((obj) =>
    futureMonths.includes(obj.mes)
  );

  const stockLack = futureMonthsObjs.reduce((acc, curr) => {
    if (curr.otbDisponivel > 0) {
      return acc + curr.otbDisponivel;
    } else {
      return acc;
    }
  }, 0);

  const overStock = futureMonthsObjs.reduce((acc, curr) => {
    if (curr.otbDisponivel < 0) {
      return acc + curr.otbDisponivel;
    } else {
      return acc;
    }
  }, 0);

  const potentialLoss = futureMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.perdaPotencial),
    0
  );

  const sumActions = () => {
    let actionsCount = 0;
    actions.forEach((action) => (actionsCount += action.actions.length));
    return actionsCount;
  };

  return (
    <S.Container>
      <div>
        <S.Title>{t("currentStockSituation")}</S.Title>
        <S.StockSection>
          <S.Box twoRows>
            <StockTag />
            <S.Number>
              -{" "}
              {stockLack.toLocaleString(language, { maximumFractionDigits: 0 })}
            </S.Number>
          </S.Box>
          <S.Box twoRows>
            <StockTag isOver />
            <S.Number>
              {Math.abs(overStock).toLocaleString(language, {
                maximumFractionDigits: 0,
              })}
            </S.Number>
          </S.Box>
        </S.StockSection>
      </div>

      <S.RightContainer>
        <div>
          <S.Title>{t("totalOpenActions")}</S.Title>
          <S.Box>
            <S.Number>{sumActions()}</S.Number>
          </S.Box>
        </div>

        <div>
          <S.Title>{t("totalROIInActions")}</S.Title>
          <S.Box>
            <S.Number>
              {/* {potentialLoss.toLocaleString(language, {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
              })} */}
              {Number(207914).toLocaleString(language, {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
              })}
            </S.Number>
          </S.Box>
        </div>

        <div>
          <S.Title>{t("generalStatus")}</S.Title>
          <S.Box>
            <S.Number>12% / 100%</S.Number>
          </S.Box>
        </div>
      </S.RightContainer>
    </S.Container>
  );
}
