import React, { useContext } from "react";
import {
  Column,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";
import Icon from "../../../assets/icons/Chart--histogram.svg";
import Graphic from "../../../assets/icons/graphic.svg";
import { i18n } from "../../../translate/i18n";

import * as S from "./SalesGraphStep.style";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
//import { faker } from "@faker-js/faker/locale/ar";
import { salesCurveService } from './salesGraphStepHelper'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const { t } = i18n;

const header = [
  t("month"),
  t("salesUnits"),
  t("salesValueParenthesis"),
  t("participation"),
];

export default function SalesGraphStep({ onBackStep, onCompleteStep }) {
  const language = localStorage.getItem("i18nextLng") || "pt";

  const {
    selectedMonths,
    selectedCurveId,
    setSelectedCurveId,
    writeMonthLabel,
    periodTarget
  } = useContext(CreatePlanContext);
  
  const salesCurveData = salesCurveService.generateSalesCurve(selectedCurveId, selectedMonths, periodTarget);

  const tableData = salesCurveData.map(data => ({
    ...data,
    month: writeMonthLabel(data.month),
  }));
  console.group('table data:');
  console.table(tableData); // Exibe a tabela de dados
  console.log('period target: ', periodTarget); // Exibe o period target
  console.groupEnd();
  
  const months = tableData.flatMap((month) => month.month);

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={6} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="" />
          <h4>{t("salesCurve")}</h4>
        </S.Title>
        <S.Text>{t("salesCurveStepExplanation")}</S.Text>
      </Column>
      <S.Column lg={10} md={4} sm={4}>
        <S.H3>{t("accessories")}</S.H3>
        <Tabs>
          <TabList contained>
            <S.Tab>{t("chart")}</S.Tab>
            <S.Tab>{t("table")}</S.Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Chart
                type="bar"
                data={{
                  labels: months,
                  datasets: [
                    {
                      type: "bar",
                      label: t("salesValue"),
                      backgroundColor: "rgb(75, 192, 192)",
                      data: tableData.flatMap((data) => data.salesValue),
                      borderColor: "white",
                      borderWidth: 2,
                    },
                  ],
                }}
              />
            </TabPanel>
            <TabPanel>
              <S.Table aria-label={t("salesTable")}>
                <TableHead>
                  <TableRow>
                    {header.map((head) => (
                      <TableHeader>{head}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data) => (
                    <TableRow key={data.month}>
                      <TableCell>{data.month}</TableCell>
                      <TableCell>{data.salesUnits}</TableCell>
                      <TableCell>
                        {data.salesValue ? 
                          data.salesValue.toLocaleString(language, {
                            style: "currency",
                            currency: "BRL",
                          }) 
                          : '0'}
                      </TableCell>
                      <TableCell>{data.participation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </S.Table>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <S.H4>{t("otherCurves")}</S.H4>
        <S.ButtonGraphsGroup>
          <S.ButtonGraphs
            isSelected={selectedCurveId === 0}
            onClick={() => {
              setSelectedCurveId(0);
            }}
          >
            <p>{t("accessories")}</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 1}
            onClick={() => {
              setSelectedCurveId(1);
            }}
          >
            <p>{t("footwear")}</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 2}
            onClick={() => {
              setSelectedCurveId(2);
            }}
          >
            <p>{t("feminine")}</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 3}
            onClick={() => {
              setSelectedCurveId(3);
            }}
          >
            <p>{t("childrens")}</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 4}
            onClick={() => {
              setSelectedCurveId(4);
            }}
          >
            <p>{t("masculine")}</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>
        </S.ButtonGraphsGroup>

        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(4);
            }}
          >
            <ArrowLeft /> {t("back")}
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(4);
            }}
          >
            {t("completePlanning")}
          </S.Button>
        </S.ButtonSet>
      </S.Column>
    </Grid>
  );
}