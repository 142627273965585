import React, { useState } from "react";
import { TextInput, Link, Tag } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { i18n } from "../../translate/i18n";

import { doLogin } from "../../services/AuthService";

import LayoutWithoutMenu from "../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";
import ButtonGoogle from "../../components/ButtonGoogle/ButtonGoogle";

import * as S from "./login.style";

export default function Login() {
  const { t } = i18n;
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isValidate, setIsValidate] = useState({
    login: false,
    password: false,
  });

  // const handleOnChangeEmail = (value) => {
  //   const regex = new RegExp("^[w-]+@([w-]+.)+[w-]{2,4}$");
  //   if (!regex.test(value)) {
  //     return setIsValidate({
  //       login: !isValidate.login,
  //       password: isValidate.password,
  //     });
  //   }
  //   return setFormData({
  //     ...formData,
  //     email: value,
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await doLogin(formData.email, formData.password);

      if (response.token) {
        localStorage.setItem("token", response.token);
        navigate("/home");
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  return (
    <LayoutWithoutMenu>
      <S.formWrapper>
        <ButtonGoogle label={t("loginWithGoogle")} />
        <S.contentPhaseOr>
          <i />
          <span>{t("or").toLowerCase()}</span>
          <i />
        </S.contentPhaseOr>
        <S.form onSubmit={handleSubmit} noValidate>
          <TextInput
            type="email"
            id="textEmail"
            name="textEmail"
            placeholder={t("email")}
            labelText=""
            invalidText={t("invalidEmail")}
            pattern={/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/}
            // invalid={isValidate.login}
            onChange={(e) => {
              setFormData({
                ...formData,
                email: e.target.value,
              });
            }}
          />
          <TextInput.PasswordInput
            type="password"
            name="textPassword"
            id="textPassword"
            labelText=""
            placeholder={t("password")}
            // invalid={isValidate.password}
            invalidText={t("invalidPassword")}
            onChange={(e) => {
              setFormData({
                ...formData,
                password: e.target.value,
              });
            }}
          />

          {error && <Tag type="magenta">{t("invalidCredentials")}</Tag>}

          <ButtonDefault
            type="submit"
            className="submitBtn"
            disabled={loading}
            renderIcon={() => <ChevronRight />}
          >
            {t("login")}
          </ButtonDefault>

          <div className="linkResetPassword">
            <Link className="link" href="/forgotPassword">
              {t("forgotPassword")}
            </Link>
          </div>

          <div className="linkResetPassword">
            {t("noAccount")}
            <Link className="createAccount" href="/createAccount">
              {t("createAccount")}
            </Link>
          </div>
        </S.form>
      </S.formWrapper>
    </LayoutWithoutMenu>
  );
}
