import currency from 'currency.js';

export const salesCurveService = {
  percentageDistributions: {
    0: [8.0, 6.6, 7.2, 6.7, 7.9, 7.4, 8.1, 7.1, 6.8, 7.6, 11.7, 15.1],    // ACESSORIOS
    1: [6.0, 6.6, 6.1, 6.5, 6.3, 7.4, 7.6, 9.4, 8.0, 7.8, 9.5, 18.8],     // CALCADOS
    2: [6.3, 7.6, 8.8, 5.6, 5.1, 6.6, 6.9, 6.8, 7.4, 6.9, 14.1, 17.9],    // FEMININO
    3: [7.8, 6.3, 14.9, 6.4, 3.1, 9.8, 8.5, 4.6, 4.4, 7.2, 12.9, 14.1],   // INFANTIL
    4: [6.6, 5.7, 7.6, 7.8, 6.3, 8.4, 6.5, 7.4, 6.3, 5.8, 9.8, 21.8]      // MASCULINO
  },

  generateSalesCurve(curveId, months, periodTarget) {
    const sanitizedValue = periodTarget.replace(/[^\d.,]/g, ''); 
    const parsedValue = sanitizedValue.replace(/\./g, '');
    const totalValue = currency(parsedValue, { precision: 0 }).value;
    const percentages = this.percentageDistributions[curveId];
    
    return months.map((month, index) => {
      const percentage = percentages[index % 12];
      const monthValue = (percentage / 100) * totalValue;
      
      return {
        month: month,
        salesUnits: Math.round(monthValue / 100),
        salesValue: parseFloat(monthValue.toFixed(2)),
        participation: parseFloat(percentage.toFixed(2))
      };
    });
  }
};