import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { i18n } from "../../../../translate/i18n";

import * as S from "./SelectedPeriodSection.style";

export default function SelectedPeriodSection({
  monthsByYear,
  selectedMonths,
}) {
  const { t } = i18n;

  return (
    <S.OuterContainer>
      <S.Container>
        <S.Text>{t("selectedPeriod")}</S.Text>
        <S.Calendar>
          <S.MonthsDiv>
            {monthsByYear[moment().year()].map((entry) => (
              <span
                className={`${entry.id < moment().month() ? "old" : ""} ${
                  selectedMonths.includes(`${entry.id}-${entry.year}`)
                    ? "next"
                    : ""
                }`}
                key={entry.id}
              >
                {entry.month}
              </span>
            ))}
            <span className="year">{moment().year()}</span>
          </S.MonthsDiv>
          <S.MonthsDiv>
            {monthsByYear[moment().add(1, "year").year()].map((entry) => (
              <span
                className={`${
                  selectedMonths.includes(`${entry.id}-${entry.year}`)
                    ? "next"
                    : ""
                }`}
                key={entry.id}
              >
                {entry.month}
              </span>
            ))}
            <span className="year">{moment().add(1, "year").year()}</span>
          </S.MonthsDiv>
          <div>
            {monthsByYear[moment().add(2, "year").year()].map((entry) => (
              <span
                className={`${
                  selectedMonths.includes(`${entry.id}-${entry.year}`)
                    ? "next"
                    : ""
                }`}
                key={entry.id}
              >
                {entry.month}
              </span>
            ))}
            <span className="year">{moment().add(2, "year").year()}</span>
          </div>
        </S.Calendar>
      </S.Container>
    </S.OuterContainer>
  );
}
