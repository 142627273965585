import React, { useContext, useState } from "react";
import {
  Column,
  DatePicker,
  DatePickerInput,
  Grid,
  TextInput,
  ModalHeader,
  ModalBody,
} from "@carbon/react";
import { ArrowRight, ArrowLeft, CheckmarkFilled } from "@carbon/icons-react";
import Icon from "../../../assets/icons/calendar--event.svg";
import { CreatePlanContext } from "../../../contexts/CreatePlanContext";
import { i18n } from "../../../translate/i18n";

import * as S from "./CommercialDaysStep.style";

export default function CommercialDaysStep({ onCompleteStep, onBackStep }) {
  const {
    commercialDays,
    setCommercialDays,
    personalizedDates,
    setPersonalizedDates,
    selectedDates,
  } = useContext(CreatePlanContext);
  const { t } = i18n;
  const language = localStorage.getItem("i18nextLng") || "pt";

  const [input, setInput] = useState({
    name: "",
    date: "",
  });
  const [inputError, setInputError] = useState(false);
  const [noDateModal, setNoDateModal] = useState(false);

  const maxPersonalizedDates = () => {
    let count = 0;
    personalizedDates.forEach((date) => {
      if (date.selected) count += 1;
    });
    return count === 4;
  };

  const isValidDate = () => {
    const { match, day, month } = splitDate();

    if (!input.name || !match) {
      return false;
    }

    if (month < 1 || month > 12) {
      return false;
    }

    if (day < 1 || day > 31) {
      return false;
    }

    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (day > daysInMonth[month - 1]) {
      return false;
    }

    if (isDuplicatedDate()) {
      return false;
    }

    return true;
  };

  const splitDate = () => {
    const datePattern = /^(\d{2})\/(\d{2})$/;
    const match = input.date?.match(datePattern);

    let day, month;

    if (match) {
      day = parseInt(match[1], 10);
      month = parseInt(match[2], 10);
    }
    return { match, day, month };
  };

  const isDuplicatedDate = () => {
    const { day, month } = splitDate();

    return personalizedDates.some(
      (date) =>
        Number(date.day) === Number(day) && Number(date.month) === Number(month)
    );
  };

  const handleDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    if (value.length > 4) {
      value = value.slice(value.length - 4);
    }

    if (value.length >= 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }

    setInput({ ...input, date: value });
  };

  const onPersonalizedDateClick = (date, index) => {
    const isSelected = date.selected;
    const newPersonalizedDates = personalizedDates.toSpliced(index, 1, {
      ...date,
      selected: !isSelected,
    });
    setPersonalizedDates(newPersonalizedDates);
  };

  const onCheckClick = () => {
    if (isValidDate()) {
      const { day, month } = splitDate();
      setPersonalizedDates([
        ...personalizedDates,
        { ...input, day, month: month - 1, selected: true },
      ]);
      setInput({ name: "", date: "" });
      setInputError(false);
    } else {
      setInputError(true);
    }
  };

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="Datas comerciais" />
          <h4>{t("commercialDates")}</h4>
        </S.Title>
        <S.Text>{t("commercialDatesExplanation")}</S.Text>
      </Column>
      <S.Column lg={8} md={4} sm={4}>
        <S.Text black>{t("selectCommercialDates")}</S.Text>
        <S.Taglist>
          {commercialDays.map((date, index) => (
            <S.Tag
              isSelected={date?.selected}
              onClick={() => {
                const newCommercialDays = commercialDays.map((item, i) => {
                  if (i === index) {
                    return {
                      ...item,
                      selected: !item.selected,
                    };
                  }
                  return item;
                });
                setCommercialDays(newCommercialDays);
              }}
            >
              {new Date(
                new Date().getFullYear(),
                date.month,
                date.day
              ).toLocaleString(language, {
                formatMatcher: "best fit",
                month: "numeric",
                day: "numeric",
              })}{" "}
              - {date.name}
            </S.Tag>
          ))}
        </S.Taglist>

        <div>
          <S.Text black margin="0 0 10px 0">
            {t("insertCustomDate")}
          </S.Text>

          {personalizedDates.length ? (
            <S.Taglist>
              {personalizedDates.map((date, index) => (
                <S.Tag
                  isSelected={date.selected}
                  onClick={() => onPersonalizedDateClick(date, index)}
                >
                  {date.date} - {date.name}
                </S.Tag>
              ))}
            </S.Taglist>
          ) : (
            <></>
          )}

          <S.List>
            <TextInput
              labelText={t("commercialDateName")}
              placeholder={t("typeName")}
              value={input.name}
              onChange={(e) =>
                setInput({
                  ...input,
                  name: e.target.value,
                })
              }
              disabled={maxPersonalizedDates()}
            />
            <DatePicker
              datePickerType="simple"
              short={true}
              dateFormat="d/m"
              style={{ maxWidth: "100px" }}
            >
              <DatePickerInput
                placeholder="dd/mm"
                labelText={t("date")}
                value={input.date}
                onChange={handleDateChange}
                pattern="\d{2}/\d{2}"
                disabled={maxPersonalizedDates()}
              />
            </DatePicker>
            <S.ButtonSet>
              <S.CheckButtonWrapper>
                <S.Button
                  primary
                  onClick={onCheckClick}
                  disabled={!input.name || !input.date}
                >
                  <CheckmarkFilled />
                </S.Button>
              </S.CheckButtonWrapper>
            </S.ButtonSet>
          </S.List>

          {inputError && <S.Text red>{t("reviewCommercialDate")}</S.Text>}

          {maxPersonalizedDates() && (
            <S.Text red>{t("maxCommercialDates")}</S.Text>
          )}
        </div>

        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(3);
            }}
          >
            <ArrowLeft /> {t("back")}
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              if (selectedDates.length === 0) {
                setNoDateModal(true);
              } else {
                onCompleteStep(3);
              }
            }}
          >
            {t("next")}
          </S.Button>
        </S.ButtonSet>
      </S.Column>

      {noDateModal && (
        <S.ComposedModal
          open={noDateModal}
          onClose={() => setNoDateModal(false)}
        >
          <ModalHeader title={t("noDateSelected")} />
          <ModalBody>
            <p
              style={{
                marginBottom: "1rem",
              }}
            >
              {t("noCommercialDatesConfirmation")}
            </p>
          </ModalBody>
          <S.ModalButtonSet noPadding>
            <S.Button kind="secondary" onClick={() => setNoDateModal(false)}>
              {t("cancel")}
            </S.Button>
            <S.Button
              primary
              onClick={() => {
                onCompleteStep(3);
              }}
            >
              {t("next")}
            </S.Button>
          </S.ModalButtonSet>
        </S.ComposedModal>
      )}
    </Grid>
  );
}
