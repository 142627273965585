import { Loading } from "@carbon/react";

import * as S from "./GeneralView.style";

import ItemView from "./ItemView/ItemView";
import IndexesSection from "./IndexesSection/IndexesSection";

export default function GeneralView({
  otbData,
  setOtbData,
  futureMonths,
  actions,
  getAllActions,
}) {
  const getCategoryActions = (category) => {
    const categoryActions = actions.find(
      (actionObj) => actionObj.category === category[0].categoria
    );
    return actions?.length ? categoryActions.actions : [];
  };

  return futureMonths.length ? (
    <>
      <IndexesSection
        otbData={otbData}
        futureMonths={futureMonths}
        actions={actions}
      />
      <S.Wrapper>
        {actions && actions.length > 0 ? (
          <div className="lista">
            {otbData.map((category) => (
              <ItemView
                categoryData={category}
                setOtbData={setOtbData}
                futureMonths={futureMonths}
                actions={getCategoryActions(category)}
                getAllActions={getAllActions}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </S.Wrapper>
    </>
  ) : (
    <S.LoadingWrapper>
      <Loading withOverlay={false} />
    </S.LoadingWrapper>
  );
}
