import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ModalHeader, ModalBody, ComposedModal } from "@carbon/react";
import { CreatePlanContext } from "../../../contexts/CreatePlanContext";
import { i18n } from "../../../translate/i18n";

import * as S from "./SummaryModal.style";
import { savePlan } from "../../../services/PlanningService";
import { saveManySalesChannels } from "../../../services/SalesChannelService";
import { saveManyCommercialDates } from "../../../services/CommercialDatesService";
import { updateCategories } from "../../../services/ProductCategoriesService";
import { updateProducts } from "../../../services/Products.service";

export default function SummaryModal({
  showSummaryModal,
  setShowSummaryModal,
}) {
  const {
    currentYear,
    yearTarget,
    inflation,
    lastYearRevenue,
    periodTarget,
    selectedMonths,
    channels,
    leadTime,
    useCategories,
    categories,
    writeMonthLabel,
    selectedDates,
    selectedCurve,
    monthAbbreviations,
    setOtbData,
    setOperationId,
    setActions,
  } = useContext(CreatePlanContext);

  const { t } = i18n;

  const navigate = useNavigate();

  const [result, setResult] = useState(false);

  const firstMonth = selectedMonths[0];
  const lastMonth = selectedMonths[selectedMonths.length - 1];

  const writePeriod = () => {
    if (selectedMonths.length) {
      return (
        writeMonthLabel(firstMonth) +
        `${selectedMonths.length > 1 ? ` - ${writeMonthLabel(lastMonth)}` : ""}`
      );
    } else return "";
  };

  const isChannelSelected = Object.values(channels).some(
    (channelValue) => channelValue > 0
  );

  const getCategoryName = (id) => {
    const data = categories;
    const nameProperty = useCategories ? "name" : "produto";
    return data.find((product) => product.id === id)?.[nameProperty];
  };

  const writeCommercialDate = (date) => {
    const monthIndex = parseInt(date.month, 10);
    const monthAbbreviation = monthAbbreviations[monthIndex];

    return `${date.day}/${monthAbbreviation}`;
  };

  const getOnlyNumbers = (str) => {
    return str.toString().match(/\d+/g)?.join("") || "";
  };

  const transformToDate = (str) => {
    const [month, year] = str.split("-").map(Number);
    return new Date(year, month);
  };

  const submitPlan = async (token) => {
    setResult("loading");
    const toSave = {
      yearTarget: getOnlyNumbers(yearTarget),
      year: currentYear,
      periodTarget: getOnlyNumbers(periodTarget),
      startDate: transformToDate(firstMonth),
      endDate: transformToDate(lastMonth),
      curve: selectedCurve,
    };

    if (lastYearRevenue) {
      toSave.lastYearRevenue = lastYearRevenue;
      toSave.indexInflation = inflation;
    }

    try {
      const language = localStorage.getItem("i18nextLng") || "pt";
      const result = await savePlan(token, toSave, leadTime, language);
      const {
        calculatedOtb,
        operationId: generatedOperationId,
        actions,
      } = result;
      setOtbData(calculatedOtb);
      setOperationId(generatedOperationId);
      setActions(actions);
      setResult("success");
    } catch (error) {
      console.log(error);
      setResult("error");
    }
  };

  const validChannelsArray = () => {
    const result = Object.keys(channels).map((channelName) => {
      if (channels[channelName]) {
        return {
          name: channelName,
          percentage: channels[channelName],
        };
      }
    });
    return result.filter((res) => res);
  };

  const submitChannels = async (token) => {
    const channelsArray = validChannelsArray();

    try {
      if (channelsArray.length > 0) {
        await saveManySalesChannels(token, channelsArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatCommercialDates = () => {
    return selectedDates.map((date) => {
      const formattedDate = new Date();
      formattedDate.setMonth(date.month - 1);
      formattedDate.setDate(date.day);
      return { ...date, date: formattedDate };
    });
  };

  const submitCommercialDates = async (token) => {
    const dates = formatCommercialDates();
    try {
      if (dates.length > 0) {
        await saveManyCommercialDates(token, dates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitLeadTimes = async (token) => {
    try {
      if (useCategories) {
        await updateCategories(token, leadTime);
      } else {
        await updateProducts(token, leadTime);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    const token = localStorage.getItem("token");

    await Promise.all([
      // submitPlan(token),
      // submitChannels(token),
      // submitCommercialDates(token),
      // submitLeadTimes(token),
    ]);
    setResult("success");
  };

  return (
    <>
      <S.ComposedModal
        open={showSummaryModal}
        onClose={() => setShowSummaryModal(false)}
      >
        <ModalHeader title={t("confirmationOfPlanningData")} />
        <ModalBody>
          <p>{t("beforeProceedingCheckData")}</p>

          <S.SectionList>
            <div>
              <h5>
                {t("targetFor")} {currentYear}
              </h5>
              <p>R$ {yearTarget}</p>
            </div>

            <S.TargetContainer>
              <div>
                <h5>{t("planningPeriod")}</h5>
                <p>{writePeriod()}</p>
              </div>
              <div>
                <h5>{t("targetForPeriod")}</h5>
                <p>R$ {periodTarget}</p>
              </div>
            </S.TargetContainer>

            <div>
              <h5>{t("salesChannels")}</h5>

              {!isChannelSelected ? (
                <p>{t("noSalesChannelDefined")}</p>
              ) : (
                <div>
                  {channels.fisica ? (
                    <S.Channel>
                      <p>{t("physicalStore")}</p>
                      <p>{channels.fisica}%</p>
                    </S.Channel>
                  ) : (
                    <></>
                  )}
                  {channels.digital ? (
                    <S.Channel>
                      <p>{t("digital")}</p>
                      <p>{channels.digital}%</p>
                    </S.Channel>
                  ) : (
                    <></>
                  )}
                  {channels.atacado ? (
                    <S.Channel>
                      <p>{t("wholesale")}</p>
                      <p>{channels.atacado}%</p>
                    </S.Channel>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>

            <div>
              <h5>{t("deliveryTime")}</h5>
              {leadTime.map((category) => (
                <S.Channel key={category.id}>
                  <p>{getCategoryName(category.id)}</p>
                  <p>
                    {category.leadTime} {t("days")}
                  </p>
                </S.Channel>
              ))}
            </div>

            <div>
              <h5>{t("commercialDates")}</h5>
              {selectedDates.length ? (
                selectedDates.map((date, index) => (
                  <p key={index}>
                    {writeCommercialDate(date)} - {date.name}
                  </p>
                ))
              ) : (
                <p>{t("noCommercialDateDefined")}</p>
              )}
            </div>

            <div>
              <h5>{t("salesCurve")}</h5>
              <p>{selectedCurve}</p>
            </div>
          </S.SectionList>
        </ModalBody>
        <S.ModalButtonSet noPadding>
          <S.Button kind="secondary" onClick={() => setShowSummaryModal(false)}>
            {t("reviewData")}
          </S.Button>
          <S.Button primary onClick={onSubmit}>
            {t("complete")}
          </S.Button>
        </S.ModalButtonSet>
      </S.ComposedModal>

      <ComposedModal open={result} size="sm" preventCloseOnClickOutside>
        {result === "loading" && (
          <>
            <ModalHeader title={t("pleaseWait")} buttonOnClick={() => {}} />
            <ModalBody>{t("yourPlanningIsBeingCreated")}</ModalBody>
          </>
        )}
        {result === "success" && (
          <>
            <ModalHeader
              title={t("success")}
              buttonOnClick={() => navigate("/otb")}
            />
            <ModalBody>{t("yourPlanningWasCreated")}</ModalBody>
            <S.ModalButtonSet>
              <S.Button primary onClick={() => navigate("/otb")}>
                {t("goToPlanning")}
              </S.Button>
            </S.ModalButtonSet>
          </>
        )}
        {result === "error" && (
          <>
            <ModalHeader
              title={t("errorOccurred")}
              buttonOnClick={() => setResult(false)}
            />
            <ModalBody>{t("errorCreatingPlanning")}</ModalBody>
            <S.ModalButtonSet>
              <S.Button kind="secondary" onClick={() => setResult(false)}>
                {t("goBackToReviewPlanning")}
              </S.Button>
            </S.ModalButtonSet>
          </>
        )}
      </ComposedModal>
    </>
  );
}
