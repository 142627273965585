import React, { useEffect, useState } from "react";
import { SelectItem } from "@carbon/react";
import { i18n } from "../../../../translate/i18n";

import * as S from "./EditPlan.style";

import PlanModal from "../../GeneralView/ItemView/PlanModal/PlanModal";

export default function EditPlan({
  otbData,
  futureMonths,
  planMonths,
  setOtbData,
}) {
  const { t } = i18n;

  const categories = otbData.map((entry) => entry[0].categoria);

  const [selectedCategory, setSelectedCategory] = useState("");

  const categoryData = otbData.find(
    (category) => category[0].categoria === selectedCategory
  );

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [planMonths]);

  const defineCategoryName = (name) => {
    const language = localStorage.getItem("i18nextLng") || "en";
    const languageCode = language.slice(0, 2);
    if (name === "ACESSORIOS") {
      if (languageCode === "en") {
        return "ACCESSORIES";
      } else {
        return "ACESSORIOS";
      }
    }
    if (name === "ROUPAS") {
      if (languageCode === "en") {
        return "CLOTHES";
      } else {
        return "ROUPAS";
      }
    }
    if (name === "PRAIA") {
      if (languageCode === "en") {
        return "BEACHWEAR";
      } else {
        return "PRAIA";
      }
    }
    return null;
  };

  return (
    <>
      <S.Container>
        <S.Select
          labelText={t("selectCategory")}
          value={selectedCategory}
          onChange={handleChange}
        >
          {categories.map((category) => (
            <SelectItem text={defineCategoryName(category)} value={category} />
          ))}
        </S.Select>
      </S.Container>

      {selectedCategory ? (
        <PlanModal
          allMonthsCategoryData={categoryData}
          futureMonths={futureMonths}
          setOtbData={setOtbData}
        />
      ) : (
        <></>
      )}
    </>
  );
}
