import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import PrivateRouter from "./security/PrivateRoute";
import CreatePlanProvider from "./contexts/CreatePlanContext";

import GlobalStyles from "./styles/globalStyles/globalStyles";

import Login from "./pages/Login/Login";
import ErrorPage from "./pages/errorPages";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import CreatePlan from "./pages/CreatePlan/CreatePlan";
import Otb from "./pages/Otb/Otb";
import AccountCreation from "./pages/AccountCreation/AccountCreation";
import StartConfigsAdmin from "./pages/StartConfigsAdmin/StartConfigsAdmin";

const AppRoutes = () => (
  <BrowserRouter>
    <GlobalStyles />
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/createAccount" element={<AccountCreation />} />
      <Route
        path="/home"
        element={
          <PrivateRouter>
            <Dashboard />
          </PrivateRouter>
        }
      />
      <Route path="/secretAdmin" element={<StartConfigsAdmin />} />

      <Route
        path="/*"
        element={
          <CreatePlanProvider>
            <Routes>
              <Route
                path="createPlan"
                element={
                  <PrivateRouter>
                    <CreatePlan />
                  </PrivateRouter>
                }
              />
              <Route
                path="otb"
                element={
                  <PrivateRouter>
                    <Otb />
                  </PrivateRouter>
                }
              />
            </Routes>
          </CreatePlanProvider>
        }
      />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
