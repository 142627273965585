import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import AppRoutes from "./router";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Sumply</title>
    </Helmet>
    <AppRoutes />
  </React.StrictMode>
);
