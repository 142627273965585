import React, { useState } from "react";
import { Modal } from "@carbon/react";
import {
  ArrowDown,
  CheckmarkFilled,
  ChevronRight,
  CircleDash,
} from "@carbon/icons-react";
import { i18n } from "../../../../translate/i18n";

import * as S from "./ItemView.style";

import ButtonDefault from "../../../../components/ButtonDefault/ButtonDefault";
import StockTag from "../StockTag/StockTag";
import PlanModal from "./PlanModal/PlanModal";

export default function ItemView({
  categoryData,
  setOtbData,
  futureMonths,
  actions,
  getAllActions,
}) {
  const { t } = i18n;
  const language = localStorage.getItem("i18nextLng") || "pt";

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const status = [
    { titulo: t("actionsAnalysis"), completa: true },
    { titulo: t("planAdjustment"), completa: false },
    { titulo: t("adjustmentAlignment"), completa: false },
  ];

  const planMonthsObjs = categoryData.filter((entry) =>
    futureMonths.includes(entry.mes)
  );

  const otbDisponivel = planMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.otbDisponivel),
    0
  );

  const potentialLoss = planMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.perdaPotencial),
    0
  );

  const defineCategoryName = (name) => {
    const language = localStorage.getItem("i18nextLng") || "en";
    const languageCode = language.slice(0, 2);
    if (name === "ACESSORIOS") {
      if (languageCode === "en") {
        return "ACCESSORIES";
      } else {
        return "ACESSORIOS";
      }
    }
    if (name === "ROUPAS") {
      if (languageCode === "en") {
        return "CLOTHES";
      } else {
        return "ROUPAS";
      }
    }
    if (name === "PRAIA") {
      if (languageCode === "en") {
        return "BEACHWEAR";
      } else {
        return "PRAIA";
      }
    }
    return null;
  };

  const defineMockedROI = (category) => {
    if (category === "ACESSORIOS") {
      return 45280;
    }
    if (category === "ROUPAS") {
      return 1772;
    }
    if (category === "PRAIA") {
      return 160862;
    }
  };

  return (
    <>
      <div className="item">
        <S.Section>
          <S.TitleSection>
            <span className="titulo">{t("category")}</span>
            <span className="nome">
              {defineCategoryName(categoryData[0].categoria)}
            </span>
          </S.TitleSection>
        </S.Section>
        <S.Section>
          <StockTag isOver={otbDisponivel < 0} />
          <span className="numero" style={{ marginTop: "5px" }}>
            {Math.abs(otbDisponivel).toLocaleString(language, {
              maximumFractionDigits: 0,
            })}
          </span>
        </S.Section>
        <S.Section>
          <S.NumberSection>
            <span className="titulo">{t("suggestedActions")}</span>
            <span className="numero">
              {actions.length.toLocaleString(language)}
            </span>
          </S.NumberSection>
        </S.Section>
        <S.Section>
          <S.NumberSection>
            <span className="titulo">{t("actionsROI")}</span>
            <span className="numero">
              {/* {potentialLoss.toLocaleString(language, {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
              })} */}
              {defineMockedROI(categoryData[0].categoria).toLocaleString(
                language,
                {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 0,
                }
              )}
            </span>
          </S.NumberSection>
        </S.Section>
        <div>
          <S.StatusSection>
            <span className="titulo-status">{t("status")}</span>
            {status.map((status) => (
              <span className="status">
                <span>{status.titulo}</span>
                {status.completa ? (
                  <CheckmarkFilled color="green" />
                ) : (
                  <CircleDash />
                )}
              </span>
            ))}
          </S.StatusSection>
        </div>
        <button className="openBtn" onClick={() => setOpen(!open)}>
          <ArrowDown />
        </button>
      </div>
      <div className={open ? "painel show" : "painel"}>
        <S.Title>
          <S.CategoryTitle>{t("category")}</S.CategoryTitle>
          <S.ItemTitle>
            {defineCategoryName(categoryData[0].categoria)}
          </S.ItemTitle>
        </S.Title>
        <S.Content>
          <ul>
            {actions.map((action) => (
              <S.ListItem>{action}</S.ListItem>
            ))}
          </ul>
          <ButtonDefault
            className="ajustarPlano"
            renderIcon={() => <ChevronRight />}
            onClick={() => setOpenModal(true)}
          >
            {t("adjustPlan")}
          </ButtonDefault>
        </S.Content>
      </div>
      <S.Container>
        <Modal
          open={openModal}
          onRequestClose={() => setOpenModal(false)}
          size="lg"
          passiveModal
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              e.preventDefault();
            }
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <h4>{t("adjustPlan")}</h4>
            <button
              type="button"
              onClick={() => setOpenModal(false)}
              aria-label="Close"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "1.5rem",
                lineHeight: "1",
              }}
            >
              &times;
            </button>
          </div>
          {futureMonths.length ? (
            <PlanModal
              allMonthsCategoryData={categoryData}
              futureMonths={futureMonths}
              setOtbData={setOtbData}
              showTitle
              getAllActions={getAllActions}
            />
          ) : null}
        </Modal>
      </S.Container>
    </>
  );
}
