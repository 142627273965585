import React, { useContext, useEffect, useState } from "react";
import {
  Column,
  RadioButtonGroup,
  RadioButton,
  Form,
  Stack,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { CreatePlanContext } from "../../../contexts/CreatePlanContext";
import { i18n } from "../../../translate/i18n";

import LayoutWithMenu from "../../../layouts/LayoutWithMenus/LayoutWithMenus";
import Label from "../../../components/Label/Label";

import * as S from "./onboarding.style";

const radioGroup = {
  invalidText: "Invalid selection",
  warn: false,
  warnText: "Please notice the warning",
};
export default function Onboarding({ setTargetStep }) {
  const {
    inflation,
    lastYearRevenue,
    setLastYearRevenue,
    yearTarget,
    setYearTarget,
  } = useContext(CreatePlanContext);

  const { t } = i18n;

  const [showOption, setShowOption] = useState(true);

  const submitFinalizerOnboarding = (e) => {
    e.preventDefault();
    setTargetStep(false);
  };

  const formatThousands = (num) =>
    num.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  useEffect(() => {
    let newYearTarget = "";

    if (showOption) {
      setLastYearRevenue("");
    } else if (lastYearRevenue) {
      const targetNumber = Number(lastYearRevenue.replace(/[^0-9]/g, ""));
      newYearTarget = Math.ceil(
        targetNumber + targetNumber * Number(inflation)
      );
    }

    setYearTarget(newYearTarget);
  }, [showOption, lastYearRevenue]);

  return (
    <LayoutWithMenu isMenu={false}>
      <Column lg={{ offset: 2, span: 6 }}>
        <Stack gap={6}>
          <h2>{t("youAreOneStepAway")}</h2>
          <p>{t("growthProjectionExplanation")}</p>
        </Stack>

        <S.formRadio>
          <Stack gap={6}>
            <Label>{t("selectMethod")}</Label>

            <RadioButtonGroup
              name="radio-button-group"
              orientation="vertical"
              defaultSelected="manual"
              {...radioGroup}
            >
              <RadioButton
                value="manual"
                id="manual"
                labelText={t("salesTargetForThisYear")}
                className="radio"
                onClick={() => setShowOption(!showOption)}
              />
              <RadioButton
                value="automatico"
                id="automatico"
                labelText={t("useInflationIndex")}
                className="radio"
                onClick={() => setShowOption(!showOption)}
              />
            </RadioButtonGroup>
          </Stack>
        </S.formRadio>
      </Column>
      <Column lg={{ span: 6, start: 10 }}>
        {showOption && (
          <>
            <Stack gap={6}>
              <h5>{t("salesTargetForThisYear")}</h5>
              <S.code>{t("enterProjections")}</S.code>
            </Stack>
            <Form onSubmit={submitFinalizerOnboarding}>
              <Stack gap={7}>
                <S.formGroup>
                  <Select
                    id="select-tipo"
                    labelText={t("type")}
                    defaultValue="real"
                  >
                    <SelectItem value="real" text="R$" />
                  </Select>
                  <TextInput
                    id="number-valor"
                    value={yearTarget}
                    label={t("value")}
                    onChange={(entry) =>
                      setYearTarget(formatThousands(entry.target.value))
                    }
                  />
                </S.formGroup>
                <S.ButtonGreen
                  renderIcon={() => <ChevronRight />}
                  type="submit"
                  disabled={!yearTarget}
                >
                  {t("finish")}
                </S.ButtonGreen>
              </Stack>
            </Form>
          </>
        )}
        {!showOption && (
          <>
            <Stack gap={7}>
              <h5>{t("useInflationIndex")}</h5>
              <S.code>
                {t("inflationIndexUpdated")}{" "}
                <S.Link href="https://www.bcb.gov.br">
                  https://www.bcb.gov.br
                </S.Link>
              </S.code>
              <S.infoCard>{inflation}%</S.infoCard>
              <S.code>{t("insertLYRevenue")}</S.code>
              <Form onSubmit={submitFinalizerOnboarding}>
                <S.formGroup style={{ marginTop: "-10px" }}>
                  <Select id="select-tipo" labelText="Tipo" defaultValue="real">
                    <SelectItem value="real" text="R$" />
                  </Select>
                  <TextInput
                    id="number-valor"
                    value={lastYearRevenue}
                    label="Faturamento do ano anterior"
                    placeholder="Faturamento do ano anterior"
                    onChange={(entry) =>
                      setLastYearRevenue(formatThousands(entry.target.value))
                    }
                  />
                </S.formGroup>
              </Form>
              <S.ButtonGreen
                renderIcon={() => <ChevronRight />}
                type="button"
                onClick={submitFinalizerOnboarding}
                disabled={!yearTarget}
              >
                {t("finish")}
              </S.ButtonGreen>
            </Stack>
          </>
        )}
      </Column>
    </LayoutWithMenu>
  );
}
