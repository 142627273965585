const API_URL = `${process.env.REACT_APP_API_URL}/api`;

const getMetrics = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/metrics/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  throw new Error(
    JSON.stringify({
      error: "Usuário não autenticado",
      status: 401,
    })
  );
};

const getMetricsales = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/metricsales/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getMetricstock = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/metricstock/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getBusiness = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/business/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getProjecteds = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/projecteds/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getStockDaysWithPercent = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(
      `${API_URL}/dashboard/stockdayswithPercent/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getStockValues = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/stockvalues/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

const getClusterValues = async (id) => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/dashboard/clusterValues/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return await response.json();
  }

  return {
    error: "Usuário não autenticado",
    status: 401,
  };
};

export {
  getBusiness,
  getMetricsales,
  getProjecteds,
  getMetrics,
  getMetricstock,
  getStockDaysWithPercent,
  getStockValues,
  getClusterValues,
};
