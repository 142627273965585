import React, { useContext, useEffect, useState } from "react";
import { Column, Tabs, Tab, TabList, TabPanels } from "@carbon/react";
import { getActions, getOtb } from "../../services/Otb.service";
import { getLatestPlan } from "../../services/PlanningService";
import { getStatus } from "../../services/PlanningService";
import { CreatePlanContext } from "../../contexts/CreatePlanContext";
import { i18n } from "../../translate/i18n";

import * as S from "./Otb.style";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import GeneralView from "./GeneralView/GeneralView";
import FinalResults from "./FinalResults/FinalResults";
import BusinessView from "./BusinessView/BusinessView";

export default function Otb() {
  const { t } = i18n;

  const {
    otbData: previewOtbData,
    operationId,
    setOperationId,
    actions: previewActions,
  } = useContext(CreatePlanContext);
  const [index, setIndex] = useState(0);
  const [otbData, setOtbData] = useState([]);
  const [plan, setPlan] = useState({});
  const [actions, setActions] = useState([]);

  const token = localStorage.getItem("token");

  const getOtbData = async () => {
    const fetchedOtbData = await getOtb(token);
    setOtbData(fetchedOtbData);
  };

  const checkOperationStatus = async (interval) => {
    try {
      const statusData = await getStatus(token, operationId);

      if (statusData.status === "completed") {
        clearInterval(interval);
        setOperationId("completed");
      }
    } catch (err) {
      console.error(err);
      clearInterval(interval);
    }
  };

  const getAllActions = async () => {
    const language = localStorage.getItem("i18nextLng");
    const fetchedActions = await getActions(token, language);
    setActions(fetchedActions);
  };

  useEffect(() => {
    if (previewOtbData.length) {
      setOtbData(previewOtbData);

      let interval;
      checkOperationStatus(interval);
      interval = setInterval(() => checkOperationStatus(interval), 30000);

      setActions(previewActions);
    } else {
      getOtbData();
      getAllActions();
    }

    const getLatestPlanData = async () => {
      const fetchedPlan = await getLatestPlan(token);
      setPlan(fetchedPlan);
    };
    getLatestPlanData();
  }, []);

  const definePlanDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const monthsBetween =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    return Array.from({ length: monthsBetween + 1 }).map((_, i) => {
      const date = new Date(start.getFullYear(), start.getMonth() + i);
      return date.toISOString().slice(0, 10);
    });
  };

  const planMonths = definePlanDates(plan?.startDate, plan?.endDate);

  const getDateString = (jsDate) => {
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const year = jsDate.getFullYear();
    return `${year}-${month}-01`;
  };

  const today = new Date();
  const todayDateString = getDateString(today);
  const todayIndex = planMonths.indexOf(todayDateString);
  const futureMonths = planMonths.slice(todayIndex);

  function displayDate(dateString) {
    const language = localStorage.getItem("i18nextLng") || "pt";
    const date = new Date(dateString);

    return new Intl.DateTimeFormat(language, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  }

  return (
    <LayoutWithMenu isCenter={false}>
      <Column lg={16} md={8} sm={4}>
        <S.Section>
          <S.Title>
            <h3>{t("planning")}</h3>
            {plan.startDate ? (
              <p>
                {displayDate(plan.startDate)} - {displayDate(plan.endDate)}
              </p>
            ) : (
              <></>
            )}
          </S.Title>

          <Tabs
            selectedIndex={index}
            onChange={({ selectedIndex }) => setIndex(selectedIndex)}
          >
            <TabList fullWidth contained>
              <Tab>{t("planOverview")}</Tab>
              <Tab>{t("businessView")}</Tab>
              {/* <Tab>{t("Resultado Geral")}</Tab> */}
            </TabList>
            <TabPanels>
              <S.TabPanel>
                <GeneralView
                  onNavTab={() => setIndex(1)}
                  otbData={otbData}
                  setOtbData={setOtbData}
                  futureMonths={futureMonths}
                  actions={actions}
                  getAllActions={getAllActions}
                />
              </S.TabPanel>
              <S.TabPanel>
                {otbData.length ? (
                  <BusinessView
                    otbData={otbData}
                    planMonths={planMonths}
                    futureMonths={futureMonths}
                    setOtbData={setOtbData}
                  />
                ) : (
                  <></>
                )}
              </S.TabPanel>
              <S.TabPanel>
                <FinalResults />
              </S.TabPanel>
            </TabPanels>
          </Tabs>
        </S.Section>
      </Column>
    </LayoutWithMenu>
  );
}
