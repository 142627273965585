import React, { useContext, useState } from "react";
import { Column, Grid, Checkbox } from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";
import { CreatePlanContext } from "../../../contexts/CreatePlanContext";
import { i18n } from "../../../translate/i18n";

import * as S from "./LeadTimeStep.style";

import Icon from "../../../assets/icons/Delivery--truck.svg";
import SkuTable from "./SkuTable/SkuTable";

export default function LeadTimeStep({ onCompleteStep, onBackStep }) {
  const { t } = i18n;
  const { leadTime, setLeadTime, categories } = useContext(CreatePlanContext);

  const [uniqueLeadTime, setUniqueLeadTime] = useState({
    unique: false,
    value: "",
  });

  const data = categories;

  const getLeadTime = (id) => {
    return leadTime.find((obj) => obj.id === id)?.leadTime;
  };

  const defineLeadTime = (id, categoryName, inputValue) => {
    let newLeadTime = data;

    if (data.find((obj) => obj.id === id)) {
      newLeadTime = leadTime.filter((obj) => obj.id !== id);
    }

    if (inputValue === "" || Number(inputValue > 0)) {
      newLeadTime = [
        ...newLeadTime,
        { id, name: categoryName, leadTime: inputValue },
      ];
      setLeadTime(newLeadTime);
    }
  };

  const areAllComplete = () => {
    const allCategoryIds = data.map((obj) => obj.id);
    return allCategoryIds.every((id) =>
      leadTime.find((obj) => obj.id === id && obj.leadTime)
    );
  };

  const showErrorMessage = () => {
    return !uniqueLeadTime.unique && !areAllComplete() && leadTime.length
      ? true
      : false;
  };

  const disableNextButton = () => {
    if (uniqueLeadTime.unique) {
      return !uniqueLeadTime.value;
    } else {
      return !areAllComplete();
    }
  };

  const handleUniqueInput = (inputValue) => {
    setUniqueLeadTime({
      ...uniqueLeadTime,
      value: inputValue,
    });

    const newLeadTime = data.map((obj) => ({ ...obj, leadTime: inputValue }));
    setLeadTime(newLeadTime);
  };

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt={t("deliveryTime")} />
          <h4>{t("deliveryTime")}</h4>
        </S.Title>
        <S.Text>{t("leadTimeStepDescription")}</S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text margin="0 0 10px 0">{t("averageLeadTimeQuestion")}</S.Text>

        <SkuTable
          getLeadTime={getLeadTime}
          defineLeadTime={defineLeadTime}
          disableInputs={uniqueLeadTime.unique}
        />

        <S.CheckboxWrapper>
          <Checkbox
            id="checkbox"
            labelText={t("sameTimeForAll")}
            checked={uniqueLeadTime.unique}
            required
            onChange={(_, { checked }) =>
              setUniqueLeadTime({ ...uniqueLeadTime, unique: checked })
            }
          />
          {uniqueLeadTime.unique && (
            <S.InputWrapper>
              <S.TextInput
                placeholder={t("timeInDays")}
                value={uniqueLeadTime.value}
                onChange={(e) => handleUniqueInput(e.target.value)}
                inputMode="numeric"
                pattern="[0-9]*"
              />{" "}
              {t("days")}
            </S.InputWrapper>
          )}
        </S.CheckboxWrapper>

        {showErrorMessage() && (
          <S.Text color="red" margin="10px 0 0 0">
            {t("fillAllCategories")}
          </S.Text>
        )}

        <S.ButtonSet>
          <S.Button kind="ghost">{t("editCategories")}</S.Button>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(2);
            }}
          >
            <ArrowLeft /> {t("back")}
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            disabled={disableNextButton()}
            onClick={() => {
              onCompleteStep(2);
            }}
          >
            {t("next")}
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
