import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;

export async function getOtb(token) {
  const url = `${API_URL}/api/otb/`;
  const headers = { authorization: token };
  const response = await axios.get(url, { headers });
  return response.data;
}

export async function simulateOtb(token, inputs) {
  const url = `${API_URL}/api/otb/simulate`;
  const headers = { authorization: token };
  const response = await axios.post(url, inputs, { headers });
  return response.data;
}

export async function updateOtb(token, newData) {
  const url = `${API_URL}/api/otb`;
  const headers = { authorization: token };
  const response = await axios.patch(url, newData, { headers });
  return response.data;
}

export async function getActions(token, language) {
  const url = `${API_URL}/api/otb/actions/?language=${language}`;
  const headers = { authorization: token };
  const response = await axios.get(url, { headers });
  return response.data;
}
